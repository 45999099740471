import {ApiClient, AuthorizationManager, RemoteRequester} from "buenLib/communication/src";
import {Session} from "buenLib/session/Session";
import {Announcer} from "appyxJs/announcements/Announcer";
import {TagManagerConfiguration} from "buenLib/gtmNotifications/tagManager/TagManagerConfiguration";
import {SignedInUserAnnouncement} from "buenLib/gtmNotifications/announcements/userAccount/SignedInUserAnnouncement";
import {ServerErrorAnnouncement} from "buenLib/gtmNotifications/announcements/general/ServerErrorAnnouncement";
import {NetworkErrorAnnouncement} from "buenLib/gtmNotifications/announcements/general/NetworkErrorAnnouncement";
import {ApiClientTokenExpirationAnnouncement} from "buenLib/gtmNotifications/announcements/general/ApiClientTokenExpirationAnnouncement";
import {AlertFactory} from "models/alerts/AlertFactory";
import {Decimal} from "decimal.js";
import * as Sentry from '@sentry/browser';
import {VerificationState} from "buenLib/models/User";
import {Currency as CurrencyHelper} from "buenLib/domain/Currency";
import {Currency} from "buenLib/domain/CurrencyV2";
import {Market} from "buenLib/domain/Market";
import {MarketConfiguration} from "buenLib/domain/MarketConfiguration";
import {defineApiUrl} from "lib/defineApiUrl";
import {SignedInEvent} from "buenLib/gtmNotifications/events/userAccount/SignedInEvent";
import {SignedUpEvent} from "buenLib/gtmNotifications/events/userAccount/SignedUpEvent";
import {ConfirmedAccountEvent} from "buenLib/gtmNotifications/events/userAccount/ConfirmedAccountEvent";
import {ResetPasswordSentEvent} from "buenLib/gtmNotifications/events/userAccount/ResetPasswordSentEvent";
import {ResetPasswordCompletedEvent} from "buenLib/gtmNotifications/events/userAccount/ResetPasswordCompletedEvent";
import {UpdatePepDataStartedEvent} from "buenLib/gtmNotifications/events/userAccount/UpdatePepDataStartedEvent";
import {UpdatePepeDataConfirmedEvent} from "buenLib/gtmNotifications/events/userAccount/UpdatePepeDataConfirmedEvent";
import {AddAccountModalOpenedEvent} from "buenLib/gtmNotifications/events/trackEvents/AddAccountModalOpenedEvent";
import {AddAccountSecondStepStartedEvent} from "buenLib/gtmNotifications/events/trackEvents/AddAccountSecondStepStartedEvent";
import {AccountAddedEvent} from "buenLib/gtmNotifications/events/withdraws/AccountAddedEvent";
import {VerificationProcessStartedEvent} from "buenLib/gtmNotifications/events/verification/VerificationProcessStartedEvent";
import {FailedDataSubmissionInVerificationEvent} from "buenLib/gtmNotifications/events/verification/FailedDataSubmissionInVerificationEvent";
import {VerificationDocumentLoadingStartedEvent} from "buenLib/gtmNotifications/events/verification/VerificationDocumentLoadingStartedEvent";
import {VerificationDataConfirmationStartedEvent} from "buenLib/gtmNotifications/events/verification/VerificationDataConfirmationStartedEvent";
import {UserVerifiedEvent} from "buenLib/gtmNotifications/events/verification/UserVerifiedEvent";
import {WithdrawStartedEvent} from "buenLib/gtmNotifications/events/withdraws/WithdrawStartedEvent";
import {WithdrawConfirmEvent} from "buenLib/gtmNotifications/events/withdraws/WithdrawConfirmEvent";
import {ExecutedWithdrawEvent} from "buenLib/gtmNotifications/events/withdraws/ExecutedWithdrawEvent";
import {OrderExecutedEvent} from "buenLib/gtmNotifications/events/orders/OrderExecutedEvent";
import {OrderStartedEvent} from "buenLib/gtmNotifications/events/orders/OrderStartedEvent";
import {SendCoinStartedEvent} from "buenLib/gtmNotifications/events/coin/SendCoinStartedEvent";
import {SendCoinConfirmEvent} from "buenLib/gtmNotifications/events/coin/SendCoinConfirmEvent";
import {ActionAfterOrderEvent} from "buenLib/gtmNotifications/events/trackEvents/ActionAfterOrderEvent";
import {NetworkErrorEvent} from "buenLib/gtmNotifications/events/generalErrors/NetworkErrorEvent";
import {ServerErrorEvent} from "buenLib/gtmNotifications/events/generalErrors/ServerErrorEvent";
import {ValidationErrorEvent} from "buenLib/gtmNotifications/events/generalErrors/ValidationErrorEvent";
import {ErrorMessageContentFactory} from "../models/ErrorMessageContentFactory";
import {MarketTicker} from 'models/MarketTicker';


export class App {
    constructor() {
        this.handleAuthorizationError = this.handleAuthorizationError.bind(this);
        this.handleServerError = this.handleServerError.bind(this);
        this.handleExceptionsFromAPIClient = this.handleExceptionsFromAPIClient.bind(this);

        this._session = new Session();
        this._announcer = new Announcer();
        this._initializeAnnouncer();

        this.setUpApiClient();
    }

    async initialize() {
        this.tagManagerConfiguration().evaluate();
    }

    loginUser(userId, email, token, extra) {
        let user = this.session().loginUser(userId, email, token, extra);
        const anAnnouncement = new SignedInUserAnnouncement(user);
        this.announcer().announce(anAnnouncement);
        this.addSentryExtraUserData();
        return user;
    }

    logOutUser() {
        this.session().logOutUser();
        this.removeSentryExtraUserData();
    }

    productName() {
        return "Buenbit"
    }

    technicalSupportMail() {
        return `soporte@${this.productName().toLowerCase()}.com`
    }

    legalDepartmentMail() {
        return `compliance@${this.productName().toLowerCase()}.com`
    }

    helpUrl() {
        return `https://ayuda.${this.productName().toLowerCase()}.com`
    }

    termsAndConditionsUrl() {
        return 'https://buenbit.com/tyc/terminos-y-condiciones-ar';
    }

    privacyUrl() {
        return 'https://buenbit.com/politicas-privacidad';
    }

    eventsTypes() {
        return [
            SignedInEvent, SignedUpEvent, ConfirmedAccountEvent, ResetPasswordSentEvent, ResetPasswordCompletedEvent,
            AddAccountModalOpenedEvent, AccountAddedEvent, ExecutedWithdrawEvent, OrderExecutedEvent,
            VerificationProcessStartedEvent, FailedDataSubmissionInVerificationEvent,
            VerificationDocumentLoadingStartedEvent, VerificationDataConfirmationStartedEvent, UserVerifiedEvent,
            WithdrawStartedEvent, OrderStartedEvent, ActionAfterOrderEvent, NetworkErrorEvent, ServerErrorEvent,
            ValidationErrorEvent, AddAccountSecondStepStartedEvent, WithdrawConfirmEvent, SendCoinStartedEvent,
            SendCoinConfirmEvent, UpdatePepDataStartedEvent, UpdatePepeDataConfirmedEvent
        ];
    }

    tagManagerConfiguration() {
        return new TagManagerConfiguration()
    }

    updateSessionStatus(sessionStatus, forceDefault = false) {
        // TODO: remove forceDefault, this is needed because sessionStatus could be incomplete
        let user = this.currentUser();
        let sanitizedSessionStatus = this._asSanitizedSessionStatus(sessionStatus, forceDefault);
        user.update(sanitizedSessionStatus);
        this.session().saveUser();
    }

    reload() {
        window.location.reload();
    }

    getAlerts() {
        if (this.currentUser().areOperationsSuspended()) {
            const factory = new AlertFactory();
            return [factory.alertWithText(this.currentUser().suspensionMessage())]
        } else {
            return this._verificationAlerts();
        }
    }

    apiClient() {
        if (this._client === null) {
            this.setUpApiClient();
        }

        return this._client;
    }

    announcer() {
        return this._announcer
    }

    session() {
        return this._session;
    }

    currentUser() {
        return this._session.user()
    }

    addSentryExtraUserData() {
        let currentUser = this.currentUser();
        let currentEmail = this.currentUser() ? currentUser.email() : "not logged";
        Sentry.configureScope((scope) => {
            scope.setUser({"email": currentEmail});
        });
    }

    removeSentryExtraUserData() {
        Sentry.configureScope((scope) => {
            scope.setUser({"email": "not logged"});
        });
    }

    isRiskWallet(risk) {
        const riskLevels = {
            unknown: 0,
            lowRisk: 1,
            highRisk: 2
        }
        return riskLevels[risk] > 1;
    }

    routes() {
        return {
            index: '/',
            session: {
                signUp: '/registro',
                confirm: '/confirmar',
                forgotPassword: '/olvide',
                recoveryPassword: '/recuperar',
                authDevice: '/auth/device',
            },
            verification: '/verificacion',
            dashboard: {
                home: '/dashboard',
                buyAndSell: {
                    homeBuy: '/dashboard/comprar',
                    homeSell: '/dashboard/vender',
                },
                depositAndWithdraw: {
                    homeWithdraw: '/dashboard/retirar',
                    homeDeposit: '/dashboard/ingresar',
                },
                invest: '/dashboard/invertir',
                user: {
                    profile: '/dashboard/perfil',
                    configuration: '/dashboard/configuracion',
                    referred: '/dashboard/referidos'
                },
            },
            downloadApp: '/download-app',
            maintenance: '/mantenimiento',
            authorize: '/autorizar',
            pack: '/pack',
            giftCard: {
                gift: '/giftcard',
                acc: '/account/referral'
            },
        }
    }

    marketDependentRoutes(marketIdentifiers) {
        return {
            dashboard: {
                buyAndSell: {
                    ...this._generateRoutesForBuyAndSell(marketIdentifiers)
                }
            }
        }
    }

    currencyDependentRoutes(currencyCodes) {
        return {
            dashboard: {
                depositAndWithdraw: {
                    ...this._generateRoutesForDepositAndWithdraw(currencyCodes),
                },
            }
        }
    }

    _generateRoutesForDepositAndWithdraw(currencyCodes) {
        let routesForDepositAndWithdraw = {};
        currencyCodes.forEach((currency) => {
            routesForDepositAndWithdraw[currency] = {
                withdraw: `/dashboard/retirar/${currency}`,
                deposit: `/dashboard/ingresar/${currency}`,
            }
        });
        return routesForDepositAndWithdraw;
    }

    _generateRoutesForBuyAndSell(marketIdentifiers) {
        let routes = {}
        marketIdentifiers.forEach((marketIdentifier) => {
            routes[marketIdentifier] = {
                buy: `/dashboard/comprar/${marketIdentifier}`,
                sell: `/dashboard/vender/${marketIdentifier}`
            }
        });
        return routes;
    }

    async fetchCurrencies() {
        // it tries to obtain the currencies, if it is not able to do that it returns an empty array
        return this._client.getCurrencies()
            .then((typedResponse) => {
                if (!typedResponse) return [];
                return typedResponse
                    .currencyList()
                    .map((currencyData) => new Currency(currencyData));
            });
    }

    async fetchTaxes () {
      return this._client.getTaxes()
            .then((response) => {
                if (!response) return [];
                return response;
            });
    }

    async fetchMarkets() {
        // it tries to obtain the markets, if it is not able to do that it returns an empty array
        return this._client.getMarkets()
            .then((typedResponse) => {
                if (!typedResponse) return [];
                return typedResponse
                    .marketsList()
                    .map(marketData => {
                        const { identifier, minimumVolumeForOrder, bidUnit, askUnit, enabled } = marketData;
                        return new Market(
                            identifier,
                            bidUnit,
                            askUnit,
                            new MarketConfiguration({
                                minimumVolumeForOrder: new Decimal(minimumVolumeForOrder),
                                enabled,
                            })
                        );
                    })
					.filter(market => {
						let enabled_currencies = CurrencyHelper.webappEnabledCurrencies();
						let bid_currency = market.bidCurrency();
						let ask_currency = market.askCurrency();
						return (
							enabled_currencies.includes(bid_currency)
							&&
							enabled_currencies.includes(ask_currency)
						);
					});
			});
    }

    // TODO: remove when decouple backoffice and buenLib
    currencies() {
        return [
            CurrencyHelper.DAI,
            CurrencyHelper.BTC,
            CurrencyHelper.ETH,
            CurrencyHelper.USD,
            CurrencyHelper.ARS,
        ]
    }

    setUpRequester() {
        const remoteApiUrl = defineApiUrl();
        const authorizationManager = new AppAuthorizationManager();
        return new RemoteRequester(remoteApiUrl, authorizationManager);
    }

    handleServerError(response) {
        this._announcer.announce(new ServerErrorAnnouncement(this.currentUser(), response));
    }

    handleExceptionsFromAPIClient(exception) {
        /***
         *
         * This handles fetch().catch() TypeError. More on:
         * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Checking_that_the_fetch_was_successful
         *
         * We don't want to capture this kind of errors on Sentry, so we can announce a network type error for the user
         * (supposing that CORS is not the error case) and print the error to the console
         *
         ***/

        this._announcer.announce(new NetworkErrorAnnouncement(this.currentUser(), exception));
        console.error(exception);
    }

    handleAuthorizationError(response) {
        this._announcer.announce(new ApiClientTokenExpirationAnnouncement(response))
    }

    setUpApiClient() {
        const requester = this.setUpRequester();
        this._client = new ApiClient(
            requester,
            this.handleServerError,
            this.handleAuthorizationError,
            this.handleExceptionsFromAPIClient,
        );
    }

    buildMarketTickers(marketIdentifiers, currentTickers) {
        const tickers = {};
        marketIdentifiers.forEach(marketIdentifier => {
            let stockValue = currentTickers[marketIdentifier];
            if (!stockValue) return;
            tickers[marketIdentifier] = new MarketTicker({
                marketIdentifier: marketIdentifier,
                changePercent: stockValue.changePercent,
                purchasePrice: stockValue.purchasePrice,
                sellingPrice: stockValue.sellingPrice,
            });
        })
        return tickers;
    }

    getMarketTickersFromLocalStorage() {
        let currentTickers = {};
        try {
            currentTickers = JSON.parse(localStorage.getItem("marketTickers")) || {};
        }
        catch { // prevent propagating exception in case the browser forbids using localstorage
        }
        return currentTickers;
    }

    updateMarketTickers(marketIdentifiers, marketTickersState) {
        this.apiClient().getMarketTicker(response => {
            const content = response.marketTickersAsJSON();
            const newTickers = this.buildMarketTickers(marketIdentifiers, content);
            marketTickersState.set(newTickers);
            localStorage.setItem('marketTickers', JSON.stringify(content));
        });
    }

    marketTickersGlobal() {
        return this._marketTickersGlobal;
    }

    _initializeAnnouncer() {
        const subscriber = this.tagManagerConfiguration().subscriber();
        subscriber.installOn(this._announcer, this.eventsTypes())
    }

    _verificationAlerts() {
        const verificationState = this.session().user().verificationState();
        const mapper = this.constructor.verificationAlertsMapper();
        let alerts = [];
        if (verificationState.code() in mapper) {
            const alert = mapper[verificationState.code()];
            alerts = [alert];
        }
        return alerts
    }

    _asSanitizedSessionStatus(sessionStatus, forceDefault) {
        let sanitizedSessionStatus = {...sessionStatus};
        let verificationStateCode = sessionStatus['verificationState'];
        if (verificationStateCode != null)
            sanitizedSessionStatus['verificationState'] = VerificationState.newFromCode(verificationStateCode);
        else if (forceDefault)
            sanitizedSessionStatus['verificationState'] = VerificationState.safe();
        return sanitizedSessionStatus
    }

    static verificationAlertsMapper() {
        const factory = new AlertFactory();
        return {
            [VerificationState.PENDING_APPROVAL]: factory.userVerificationPendingApprovalAlert(),
            [VerificationState.INHIBITED]: factory.customerInhibitedByTheCentralBankAlert(),
            [VerificationState.HAS_DEATH_REPORT]: factory.customerHasDeathReportAlert(),
            [VerificationState.IS_PEP_WITHOUT_VALID_DOCUMENTATION]: factory.customerPEPWithoutValidDocumentationAlert(),
            [VerificationState.IS_OBLIGATED_SUBJECT_WITHOUT_VALID_DOCUMENTATION]: factory.customerObligatedSubjectWithoutValidDocumentationAlert(),
            [VerificationState.SUSPICIOUS]: factory.suspiciousCustomerAlert(),
            [VerificationState.UNVERIFIED]: factory.unverifiedCustomerAlert(),
            [VerificationState.BLOCKED_BY_ILLEGAL_AGE]: factory.customerCannotOperateBecauseHasNoLegalAgeAlert(),
            [VerificationState.BLOCKED_BY_NO_RESIDENT]: factory.customerCannotOperateBecauseHasNoLegalResidenceAlert(),
            [VerificationState.BANNED_BY_AN_OPERATOR]: factory.customerCannotOperateBecauseWasBannedAlert(),
            [VerificationState.PENDING_BY_MISSING_DATA]: factory.customerVerificationPendingByDataRequiredAlert(),
            [VerificationState.VERIFICATION_WITH_PENDING_REVIEW]: factory.customerVerificationWithPendingReviewAlert(),
            [VerificationState.LIFE_CHECK_PENDING]: factory.customerVerificationWithPendingLifeCheck(),
            [VerificationState.LIFE_CHECK_REJECTED]: factory.customerVerificationWithRejectedLifeCheck(),
            [VerificationState.HAS_PHOTO_REQUESTS]: factory.customerHasPhotoRequests(),
            [VerificationState.ANNUAL_VALIDATION_PENDING]: factory.customerWithAnnualValidationPending(),
        }
    }

    errorMessageMapper() {
        const factory = new ErrorMessageContentFactory();
        return {
            [VerificationState.HAS_DEATH_REPORT]: factory.customerHasDeathReportErrorContent(),
            [VerificationState.INHIBITED]: factory.customerInhibitedByTheCentralBankErrorContent(),
        };
    }
}

class AppAuthorizationManager extends AuthorizationManager {
    token() {
        return app.currentUser().token();
    }
}

export let app = new App();
