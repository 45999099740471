import React, { useContext } from 'react';
import {BrowserRouter as Router, Redirect, Route} from "react-router-dom"
import {PrivateRoute} from "app/routes/PrivateRoute"
import LoginController from 'controllers/authentication/LoginController';
import ForgotPasswordController from 'controllers/authentication/ForgotPasswordController';
import PasswordRecoveryController from 'controllers/authentication/PasswordRecoveryController';
/* import SignUpController from "controllers/authentication/SignUpController"; */
import VerificationController from "controllers/VerificationController";
import ConfirmAccountController from "controllers/authentication/ConfirmAccountController";
import DashboardRoutes from "app/routes/DashboardRoutes";
import {app} from "app/app";
import 'assets/sass/common/Main.scss';
import {ServerErrorAnnouncement} from "buenLib/gtmNotifications/announcements/general/ServerErrorAnnouncement";
import {NetworkErrorAnnouncement} from "buenLib/gtmNotifications/announcements/general/NetworkErrorAnnouncement";
import {Modal} from "components/common/Modal";
import {ErrorBoundary} from "sentry/ErrorBoundary";
import DownloadAppPage from "components/DownloadAppPage";
import UnsupportedBrowserConfigurationPage from "components/UnsupportedBrowserConfigurationPage";
import "outdated-browser-rework/dist/style.css";
import outdatedBrowser from "outdated-browser-rework";
import OauthAuthorizationController from "../../controllers/OauthAuthorizationController";
import GiftcardLandingController from "../../controllers/giftCard/GiftcardLandingController";
import PackController from "../../controllers/pack/packController";
import MaintenancePage from 'components/MaintenancePage';
import { AppContextProvider } from 'app/state/AppContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {getSetting, KEYS} from 'settings/settings.js';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ThemeContext } from 'context/ThemeContext';

const GlobalStyle = createGlobalStyle`
    html {
        font-size: 1rem;
    }

    body {
        margin: 0;
        padding: 0;
        font-family: 'Inter', sans-serif;
        background: ${({ theme }) => theme.color.accent.background};
    }

    h1 {
        font-family: 'Fraunces', serif;
        font-weight: 400;
        font-size: 1.75rem;
        line-height: 20px;
        color: ${({ theme }) => theme.color.accent._8};
        margin: 30px 0;
    }

    h2 {
        margin: 10px 0 15px;
        font-weight: 500;
        font-size: 1.375rem;
        color: ${({ theme }) => theme.color.accent._8};
    }

    h3 {
        font-family: 'Fraunces', serif;
        margin: 0 0 20px;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 20px;
        color: ${({ theme }) => theme.color.accent._8};
    }

    h4 {
        font-weight: 600;
        font-size: 0.875rem;
        margin: 0;
        color: ${({ theme }) => theme.color.accent._8};
    }

    h5 {
        margin: 10px 0;
        font-weight: 500;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.color.accent._8};
    }

    p,
    ul li {
        font-size: 1rem;
        color: ${({ theme }) => theme.color.accent._8};
        margin: 5px 0;
        word-break: break-word;

        & b {
            font-weight: 600;
        }
    }

    .error {
        color: ${({ theme }) => theme.color.accent.error};
    }

    .disclaimer {
        font-size: 12px;
        color: ${({ theme }) => theme.color.accent._5};
    }

    .highlighted {
        color: ${({ theme }) => theme.color.accent.highlighted};
    }

    .green {
        color: ${({ theme }) => theme.color.accent.green};
    }

    .black,
    .black * {
        color: #000;
    }

    .m0 {
        margin:0;
    }

    .monospace {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
    }

    a {
        color: ${({ theme }) => theme.color.accent.highlighted};

        &:hover {
            color:inherit;
        }
    }

    hr {
        border-color: ${({ theme }) => theme.color.accent._2};
    }

    @media only screen and (max-width: 1024px) {
        html {
            font-size: 0.875rem;
        }

        h1 {
            line-height: 30px;
        }
    }

    @media only screen and (max-width: 580px) {
        .operation-form {
            flex-direction:column;
        }

        .operation-form > div:nth-child(2) {
            margin: 0!important;
        }

        .operation-form > div:nth-child(2) svg {
            transform: rotate(90deg);
        }

        .profile-group {
            width: 100%;
        }
    }
`;


export default function Routes({...props}) {
    const [hasServerErrors, setHasServerErrors] = React.useState(false);
    const [hasNetworkErrors, setHasNetworkErrors] = React.useState(false);

    const theme = useContext(ThemeContext);

    React.useEffect(() => {
        outdatedBrowser({
            browserSupport: {
                "WebKit": 600, // In order to accept iOS WebView
                "Chrome WebView": 83 // In order to accept Android WebView
            },
            language: "es",
            messages: {
                es: {
                    outOfDate: "Detectamos que tu navegador está desactualizado",
                    unsupported: "Detectamos que tu navegador está desactualizado",
                    update: {
                        web: `Para ver ${app.productName()} correctamente, <a href="https://bestvpn.org/outdatedbrowser/es" target="_blank" id="updateLink">actualizá tu navegador</a>`,
                        googlePlay: "Por favor instalá Chrome desde Google Play",
                        appStore: "Por favor actualizá iOS desde la app de Configuraciones",
                    },
                    // You can set the URL to null if you do not want a clickable link or provide
                    // your own markup in the `update.web` message.
                    url: null,
                    callToAction: "¡Actualizá ahora!",
                    close: "Cerrar"
                }
            }
        });
        app.announcer().subscribe(NetworkErrorAnnouncement, handleNetworkErrorAnnouncement, this);
        app.announcer().subscribe(ServerErrorAnnouncement, handleServerErrorAnnouncement, this);
        if (navigator.cookieEnabled) {
            app.addSentryExtraUserData();
        }

        return () => app.announcer().unsubscribe(this); // cleanup subscription on unmount
    }, []);

    function handleServerErrorAnnouncement() {
        setHasServerErrors(true);
    }

    function handleNetworkErrorAnnouncement() {
        setHasNetworkErrors(true);
    }

    function handleServerErrorModalOnClose() {
        setHasServerErrors(false);
        window.location.reload()
    }

    function handleNetworkErrorModalOnClose() {
        setHasNetworkErrors(false);
    }

    function renderContent() {
        let configurationErrors = [];

        if (!navigator.cookieEnabled) {
            configurationErrors.push(
                <p>Encontramos que tu navegador tiene deshabilitado el almacenamiento de cookies. Sin ellas no podrás operar en el sitio.</p>
            );
        }

        if (configurationErrors.length > 0) {
            return <UnsupportedBrowserConfigurationPage>
                {configurationErrors}
            </UnsupportedBrowserConfigurationPage>;
        }

        const allRoutes = app.routes();

        const routesToDownloadApp = [
            allRoutes.downloadApp,
            allRoutes.session.authDevice,
            allRoutes.session.signUp
        ];

        function renderIndex(props) {
            return <>
                    {!app.currentUser().isLoggedIn()
                        ? <LoginController {...props} />
                        : <Redirect to={{pathname: allRoutes.dashboard.home, state: {from: props.location}}}/>
                    }
                </>;
        }

        function renderGiftcardController(props) {
            return <Redirect to={{
                pathname: allRoutes.giftCard.gift,
                search: props?.location?.search ?? ""
            }} />
        }

        return <>
            <GlobalStyle />
            <Router key="router">
                <Route exact path={allRoutes.index} render={renderIndex} />
                <Route exact path={allRoutes.session.forgotPassword} component={ForgotPasswordController}/>
                <Route exact path={allRoutes.session.recoveryPassword} component={PasswordRecoveryController}/>
                <Route exact path={allRoutes.giftCard.acc} render={renderGiftcardController}/>
                <Route exact path={allRoutes.giftCard.gift} component={GiftcardLandingController}/>
                <Route exact path={allRoutes.pack} component={PackController}/>
                <Route exact path={allRoutes.session.confirm} component={ConfirmAccountController}/>
                <Route exact path={allRoutes.maintenance} component={MaintenancePage}/>
                <PrivateRoute exact path={allRoutes.verification} component={VerificationController}/>
                <PrivateRoute path={allRoutes.dashboard.home} component={DashboardRoutes}/>
                <PrivateRoute exact path={allRoutes.authorize} component={OauthAuthorizationController}/>
                {
                    routesToDownloadApp.map((route, k) => <Route exact key={`key-${k}`} path={route} component={DownloadAppPage}/>)
                }
            </Router>
        </>};

    return (
        <ThemeProvider theme={theme.state.theme}>
            <ErrorBoundary>
                <GoogleReCaptchaProvider
                    reCaptchaKey={getSetting(KEYS.RECAPTCHA_SITE_KEY)}
                    language="es"
                    useEnterprise
                >
                    <Modal key="modal-server-error" type={Modal.MODAL_TYPES.danger} title="¡Ha ocurrido un error!"
                        open={hasServerErrors} onClose={handleServerErrorModalOnClose}>
                        <p>Por favor intentá de nuevo más tarde o contactá a <a
                            href={`mailto:${app.technicalSupportMail()}`}>{app.technicalSupportMail()}</a>
                        </p>
                    </Modal>
                    <Modal key="modal-network-error" type={Modal.MODAL_TYPES.warning} title="Tu conexión está fallando"
                        open={hasNetworkErrors} onClose={handleNetworkErrorModalOnClose}>
                        <p>Hay un problema con tu conexión a Internet. Por favor intentá de nuevo más tarde.</p>
                    </Modal>
                    <AppContextProvider>
                            {renderContent()}
                    </AppContextProvider>
                </GoogleReCaptchaProvider>
            </ErrorBoundary>
        </ThemeProvider>
    )
}
